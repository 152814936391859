import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  labelConfig,
  DocLanguage,
} from 'src/common';
import type {
  Error,
  ContentPublicationsResourceForEdit,
  ContentPublicationsResource,
  GetDocumentListAsyncOptionalParams
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import {$enum} from "ts-enum-util";
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';
import { PagedList } from 'src/types/pagination';
import parse from 'html-react-parser';

export default class ContentPublicationsModel extends AppModel 
{
  private static _instance: ContentPublicationsModel;
  readonly Code = new FormModel('code', 'Code', 200);
  readonly Titre = new FormModel('title','Titre',600);
  readonly Authors = new FormModel('authors','Auteur',2000);
  readonly Collection = new FormModel('collection','Collection',45);
  readonly Callnumber = new FormModel('callnumber','Cote du livre',45);
  readonly Partner = new FormModel('publisher','Partenaire',250);
  readonly Cover = new FormModel('cover','Page couverture',2000);
  readonly DocumentType = new FormModel('documentType','Type de contenu',50);
  readonly Published = new FormModel('published','Date de publication');
  readonly Lang = new FormModel('lang','Langue', 2);
  readonly IsFrequentlyConsulted = new FormModel('isFrequentlyConsulted','Référence pratique');

  constructor(){
    super('/content/publications');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'CONTENT_PUBLICATIONS';
    this._headerTitle = 'Liste des publications';
    this._btnAddText = 'Ajouter une publication';
    this.Path.PathName = '/contents/publications';

    //Dialog
    this.Dialog.Header = 'Supprimer la publication';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'la publication';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Publications',
      href: this.Path.Home,
      visible: true
    }
  }

  getHeadCells(){
    return [
      {
        id: this.Code.Name, width: '15%', numeric: false, disablePadding: false, label: this.Code.Label
      },
      {
        id: this.Titre.Name, width: '25%', numeric: false, disablePadding: false, label: this.Titre.Label
      },
      {
        id: this.Authors.Name, width: '15%', numeric: false, disablePadding: false, label: this.Authors.Label
      },
      {
        id: this.Collection.Name, width: '15%', numeric: false, disablePadding: false, label: this.Collection.Label
      },
      {
        id: this.Searchable.Name, width: '10%', numeric: false, disablePadding: false, label: this.Searchable.Label
      },
      {
        id: this.IsFrequentlyConsulted.Name, width: '15px', numeric: false, disablePadding: false, label: this.IsFrequentlyConsulted.Label
      },
      {
        id: this.Visible.Name, width: '5px', numeric: false, disablePadding: false, label: this.Visible.Label
      },
      {
				id: '', width: '0%'
			},
    ];
  }

  static getInstance(): ContentPublicationsModel {
    if (!ContentPublicationsModel._instance) {
      ContentPublicationsModel._instance = new ContentPublicationsModel();
    }
    return ContentPublicationsModel._instance;
  }

  static getInitialValues(values: ContentPublicationsResource) : ContentPublicationsResource {
    return {
      ...values,
      lang: values.lang || $enum(DocLanguage).getKeyOrDefault(DocLanguage.fr),
      title: values.title ? parse(values.title) as string : '',
      visible: values.visible || false,
      searchable: values.searchable || false,
      isFrequentlyConsulted: values.isFrequentlyConsulted || false
    }
  }

  static getLanguageByVal(val: string){
    return $enum(DocLanguage).getValueOrDefault(val);
  }

  async getContentPublicationsPaginated(params: GetDocumentListAsyncOptionalParams): Promise<PagedList<ContentPublicationsResource>> {
    let pagedList: PagedList<ContentPublicationsResource>;
    await axios.get<PagedList<ContentPublicationsResource>>(this.route, { params }).then(async (response) => {
      const { status, data } = response;
      if (status === Success) {
        pagedList = data;
      }
    },
    async (error) => {
      this.error = error;
      if(!this.skipHandleError){
        await this.handleError(this.error);
      }
    });
    return pagedList;
  }
  
  async getContentPublicationById(id: number): Promise<ContentPublicationsResource> {
    let contentPublication: ContentPublicationsResource;
    await axios.get<ContentPublicationsResource>(`${this.route}/${id}`).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          contentPublication = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return contentPublication;
  }

  async updateContentPublication(submitData: ContentPublicationsResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<ContentPublicationsResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(async (response) => {
      const { status } = response;
      if (status === Success) {
        result = response;
        result.message = 'Publication de contenu modifié.';
      }  
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(id: number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then((response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Publication de contenu supprimé.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }
}
