import React, { useEffect, useReducer, useState } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  AccessModel,
  _contentPublication,
  fetchContentPublications,
  useNavigate,
  root,
  ITEMS_PER_PAGE
} from 'src/common';
import type { 
  ContentPublicationsResource,  
  GetDocumentListAsyncOptionalParams, 
  Sort 
} from 'src/common/types';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import ContentPublicationsModel from 'src/model/content/ContentPublications';
import useCollection from 'src/functions/hooks/collection';
import { PagedList } from 'src/types/pagination';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  contentPublications: [],
  paged: {
    totalCount: 0,
    pageSize: ITEMS_PER_PAGE,
    currentPage: 1,
    totalPages: 0,
  },
  isLoading: false,
  isEmptyList: true
};

const ContentPublicationsListView: FC = () => {
  const model = new ContentPublicationsModel();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [deleted, setDeleted] = useState<number>(0);
  const [state, dispatch] = useReducer(_contentPublication, initialState);
  const collection = useCollection();

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const { search, pathname } = location;
        let results: PagedList<ContentPublicationsResource>;
        let params: GetDocumentListAsyncOptionalParams = { pageNumber: 1, pageSize: ITEMS_PER_PAGE };
        if(search){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            const { Filters: { page, sort, query, collection }} = model; 
            params = {
              ...params,
              pageNumber: +model.getQueryParam(page) + 1,
              sortOptions: model.getQueryParam(sort) as Sort || 'asc',
              searchTerm: model.getQueryParam(query),
              collection: model.getQueryParam(collection)
            };
            results = await model.getContentPublicationsPaginated(params);
            if (!model.error) {
              dispatch(fetchContentPublications(results.list,{...results}, true, !Array.isArray(results.list) || results.list.length === 0, true));
            }
          }
        }else{
          results = await model.getContentPublicationsPaginated(params);
          if (!model.error) {
            dispatch(fetchContentPublications(results.list,{ ...results}, true, !Array.isArray(results.list) || results.list.length === 0, true));
          }
        }
      }
    })();
  }, [location, deleted]);

  const handleDelete : (id: number) => Promise<void> = async id => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        setDeleted(deleted + 1);
    }
  };

  const { contentPublications, paged, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            contentPublications={contentPublications}
            onDeleteContentPublication={handleDelete}
            collection={collection}
            isEmptyList={isEmptyList}
            model={model}
            navigate={navigate}
            paged={paged}
            location={location}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ContentPublicationsListView;

